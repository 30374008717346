<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Cài đặt điểm tích lũy'">
        <template v-slot:preview>
          <b-form ref="form" lazy-validation class="row">
            <b-container>
              <b-row>
                <b-col md="4">
                  <b-row class="mb-4">
                    <b-col md="6">
                      <b-form-select
                        id="input-4"
                        v-model="selectedCompany"
                        :options="listCompany"
                        required
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                        size="sm"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >--Chọn công ty --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-row class="row-center-vertical">
                    <b-col md="3">
                      <p class="text-lable">Tỷ lệ tích điểm:</p>
                    </b-col>
                    <b-col md="4">
                      <div class="input-group input-group-sm mb-3">
                        <input
                          type="text"
                          class="form-control text-right"
                          placeholder="Nhập tỉ lệ tích điểm"
                          v-model="pointRate"
                          aria-describedby="inputGroup-sizing-sm"
                          aria-label="Small"
                          v-mask="mask"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            style="color: #ffffff; background: #2ba0cb"
                            id="inputGroup-sizing-sm"
                            >VND</span
                          >
                        </div>
                      </div>
                    </b-col>
                    <b-col md="1">
                      <p class="text-lable">=</p>
                    </b-col>
                    <b-col md="4">
                      <p class="text-lable">1 điểm thưởng</p>
                    </b-col>
                  </b-row>
                  <b-row class="row-center-vertical">
                    <b-col md="3">
                      <p class="text-lable">Tỷ lệ tiêu điểm:</p>
                    </b-col>
                    <b-col md="4">
                      <div class="input-group input-group-sm mb-3">
                        <input
                          type="text"
                          class="form-control text-right"
                          placeholder="Nhập số điểm"
                          v-model="pointToMoney"
                          aria-describedby="inputGroup-sizing-sm"
                          aria-label="Small"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            style="color: #ffffff; background: #eb9d3e"
                            id="inputGroup-sizing-sm"
                            >Điểm</span
                          >
                        </div>
                      </div>
                    </b-col>
                    <b-col md="1">
                      <p class="text-lable">=</p>
                    </b-col>
                    <b-col md="4">
                      <div class="input-group input-group-sm mb-3">
                        <input
                          type="text"
                          class="form-control text-right"
                          placeholder="Nhập số tiền"
                          v-model="moneyToPoint"
                          aria-describedby="inputGroup-sizing-sm"
                          aria-label="Small"
                          v-mask="mask"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            style="color: #ffffff; background: #2ba0cb"
                            id="inputGroup-sizing-sm"
                            >VND</span
                          >
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <p style="font-size: 10px" class="text-left">
                    (Nếu không cài đặt Tỉ lệ tiêu điểm, hệ thống sẽ lấy Tỉ lệ
                    tích điểm để tính)
                  </p>
                  <hr />
                  <b-row class="row-center-vertical">
                    <b-col md="8">
                      <p class="text-lable">
                        Một lần tiêu điểm tích lũy cần sử dụng tối thiểu:
                      </p></b-col
                    >
                    <b-col md="4">
                      <div class="input-group input-group-sm mb-3">
                        <input
                          type="text"
                          class="form-control text-right"
                          placeholder="Nhập số điểm"
                          v-model="minPoint"
                          aria-describedby="inputGroup-sizing-sm"
                          aria-label="Small"
                          v-mask="mask"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            style="color: #ffffff; background: #eb9d3e"
                            id="inputGroup-sizing-sm"
                            >Điểm</span
                          >
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row class="row-center-vertical">
                    <b-col md="8">
                      <p class="text-lable">
                        Một lần tiêu điểm tích lũy chỉ được sử dụng tối đa:
                      </p></b-col
                    >
                    <b-col md="4">
                      <div class="input-group input-group-sm mb-3">
                        <input
                          type="text"
                          class="form-control text-right"
                          placeholder="Nhập số điểm"
                          v-model="maxPoint"
                          aria-describedby="inputGroup-sizing-sm"
                          aria-label="Small"
                          v-mask="mask"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            style="color: #ffffff; background: #eb9d3e"
                            id="inputGroup-sizing-sm"
                            >Điểm</span
                          >
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row class="row-center-vertical">
                    <b-col md="8">
                      <p class="text-lable">
                        Tự động tiêu điểm khi khách hàng có từ:
                      </p></b-col
                    >
                    <b-col md="4">
                      <div class="input-group input-group-sm mb-3">
                        <input
                          type="text"
                          class="form-control text-right"
                          placeholder="Nhập số điểm"
                          v-model="autoPoint"
                          aria-describedby="inputGroup-sizing-sm"
                          aria-label="Small"
                          v-mask="mask"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            style="color: #ffffff; background: #eb9d3e"
                            id="inputGroup-sizing-sm"
                            >Điểm</span
                          >
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="savePointSetting"
                    v-if="checkPermission('POINT_UPDATE')"
                    >Lưu</b-button
                  >
                </b-col>
                <b-col md="6">
                  <b-row class="mb-4 ml-4">
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch1"
                        v-model="allowAddPointProductService"
                      />
                      <label
                        class="custom-control-label text-lable"
                        for="customSwitch1"
                        >Cho phép tích điểm sản phẩm dịch vụ</label
                      >
                    </div>
                  </b-row>
                  <b-row class="mb-4 ml-4">
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch2"
                        v-model="allowAddPointRoundToDown"
                      />
                      <label
                        class="custom-control-label text-lable"
                        for="customSwitch2"
                        >Làm tròn xuống điểm tích lũy</label
                      >
                    </div>
                  </b-row>
                  <b-row class="mb-4 ml-4">
                    <p style="font-size: 10px" class="text-left">
                      VD: Cài đặt chương trình tích điểm như sau: Hóa đơn từ
                      50.000VND trở lên sẽ được tích điểm tương ứng 10% giá trị
                      hóa đơn, tỉ lệ quy đổi 1 điểm = 1000VND. Nếu khách hàng
                      mua hàng có hóa đơn trị giá 55.000VND thì được tích điểm
                      10% tương ứng 5.500VND = 5,5 điểm. Mặc định hệ thống sẽ
                      làm tròn 6 điểm. Nếu bật cài đặt này thì hệ thống sẽ tính
                      5 điểm.
                    </p>
                  </b-row>
                  <b-row class="mb-4 ml-4">
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch3"
                        v-model="sendSMS"
                      />
                      <label
                        class="custom-control-label text-lable"
                        for="customSwitch3"
                        >Xác minh sử dụng điểm tích lũy qua SMS</label
                      >
                    </div>
                  </b-row>
                  <b-row class="mb-4 ml-4">
                    <p style="font-size: 10px" class="text-left">
                      (Hệ thống sẽ gửi OTP về điện thoại khách mua hàng, khách
                      đọc OTP cho thu ngân để xác minh sử dụng điểm tích lũy. Sử
                      dụng chức năng này bạn cần đăng ký và cài đặt tài khoản
                      gửi SMS)
                    </p>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
        </template>
      </KTCodePreview>
      <KTCodePreview v-bind:title="'Chương trình tích điểm'">
        <template v-slot:toolbar>
          <div class="row">
            <router-link
              to="/points/add-point-program"
              v-if="checkPermission('POINT_INSERT')"
            >
              <button
                v-bind:style="btnCreate"
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm button"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
              </button>
            </router-link>
          </div>
        </template>
        <template v-slot:preview>
          <b-table
            :items="listData"
            :fields="fields"
            class="table-bordered table-hover col-md-12"
            :busy="onLoading"
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
            </template>
            <template v-slot:cell(id)="row">
              <div
                class="d-flex justify-content-center orderCode"
                style="cursor: pointer; color: #3699ff"
                @click="editItem(row.item)"
              >
                <span v-text="row.item.id"></span>
              </div>
            </template>
            <template v-slot:cell(status)="row">
              <div
                style="
                  justify-content: center;
                  align-items: center;
                  display: grid;
                "
              >
                <i
                  v-bind:class="{
                    'fas fa-check text-success': row.item.status == 1,
                    'fas fa-minus-circle text-danger': row.item.status != 1
                  }"
                />
              </div>
            </template>
            <template v-slot:cell(time)="row">
              <!-- <div class="d-flex justify-content-center align-items-center"> -->
              <p class="text-center">{{ row.item.startDate }}</p>
              <p class="text-center">{{ row.item.endDate }}</p>
              <!-- </div> -->
            </template>
            <template v-slot:cell(createdBy)="row">
              <p class="text-center">{{ row.item.createdBy }}</p>
              <p class="text-center">{{ row.item.createdAt }}</p>
            </template>
            <template v-slot:cell(actions)="row">
              <div class="d-flex justify-content-center">
                <b-dropdown size="sm" id="dropdown-left" no-caret right>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem; padding-right: 0px"
                      class="flaticon2-settings"
                    ></i>
                  </template>
                  <b-dropdown-item
                    @click="editItem(row.item)"
                    v-if="checkViewOnly()"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i style="font-size: 1rem" class="flaticon-eye"></i>
                      &nbsp; Chi tiết
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="editItem(row.item)"
                    v-if="!checkViewOnly()"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i style="font-size: 1rem" class="flaticon2-pen"></i>
                      &nbsp; Cập nhật
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="showDeleteAlert(row.item)"
                    v-show="checkPermission('POINT_DELETE')"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem; color: #d33"
                        class="flaticon2-rubbish-bin-delete-button"
                      ></i>
                      &nbsp; Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col>
              <p class="mt-3 text-dark" style="font-weight: 500">
                Tổng số chương trình:
                {{ totalItem }}
              </p>
            </b-col>
            <div class="d-flex justify-content-end">
              <b-col>
                <b-pagination-nav
                  class="customPagination"
                  v-if="numberOfPage >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </div>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.bootstrap-datetimepicker-widget .dropdown-menu {
  width: 21em;
}

.text-lable {
  font-weight: 550;
}
.input-group-append {
  color: darkcyan;
}
.row-center-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.orderCode:hover {
  text-decoration: underline;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
// import moment from 'moment';
import { VclTable } from 'vue-content-loading';
// Import datePicker component
// import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

// jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
//   icons: {
//     time: 'far fa-clock',
//     date: 'far fa-calendar',
//     up: 'fas fa-arrow-up',
//     down: 'fas fa-arrow-down',
//     previous: 'fas fa-chevron-left',
//     next: 'fas fa-chevron-right',
//     today: 'fas fa-calendar-check',
//     clear: 'far fa-trash-alt',
//     close: 'far fa-times-circle',
//   },
// });
import decounce from 'debounce';
import { currencyMask, unMaskPrice } from './../../../utils/common';

export default {
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true
        }
      },
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important'
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: { textAlign: 'center', width: '6%' }
        },
        {
          key: 'name',
          label: 'Chương trình',
          sortable: false
        },
        {
          key: 'desciption',
          label: 'Mô tả',
          sortable: false
        },
        {
          key: 'time',
          label: 'Thời hạn',
          sortable: false
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false
        },
        {
          key: 'createdBy',
          label: 'Người tạo',
          sortable: false
        },
        { key: 'actions', label: '' }
      ],
      listData: [],
      linkGen: pageNum => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      pointRate: 0,
      moneyToPoint: 0,
      pointToMoney: 0,
      mask: currencyMask,
      minPoint: 0,
      maxPoint: 0,
      autoPoint: 0,
      allowAddPointProductService: false,
      sendSMS: false,
      allowAddPointRoundToDown: false,
      selectedCompany: null,
      listCompany: [],
      pointSettingId: 0
    };
  },
  components: {
    KTCodePreview,
    VclTable
    // datePicker,
  },
  created() {
    this.fetchData();
    this.fetchCompany();
    this.getPointSetting();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Khách hàng', route: '/customers' },
      { title: 'Danh sách khách hàng' }
    ]);
  },
  methods: {
    onDpChange() {
      this.fetchData();
    },
    checkViewOnly: function() {
      let count = 0;
      if (localData.checkPermission('POINT_VIEW')) {
        count++;
      }
      if (localData.checkPermission('POINT_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    checkPermission(condition) {
      return localData.checkPermission(condition);
    },
    fetchData: function() {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      ApiService.setHeader();
      ApiService.get(`pointProgram?limit=10&page=${this.page}`).then(
        ({ data }) => {
          this.listData = [];
          this.totalItem = data.data.total_row;
          data.data.data.forEach((item, index) => {
            let result = {
              count:
                this.page >= 2 ? index + 1 + this.page * 10 - 10 : index + 1,
              ...item
            };
            this.listData.push(result);
          });
          this.numberOfPage = data.data.total_page;
          this.onLoading = false;
        }
      );
    },
    editItem: function(item) {
      this.$router.push({
        path: 'points/update-point-program',
        query: { id: item.id }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true
      });
    },
    deleteItem: async function(item) {
      ApiService.setHeader();
      ApiService.delete(`pointProgram?id=${item.id}`)
        .then(() => {
          this.makeToastSuccess('Xóa thành công');
          this.fetchData();
        })
        .catch(() => {
          this.makeToastFaile('Không thể xóa chương trình tích điểm!');
        });
    },
    showDeleteAlert: function(item) {
      Swal.fire({
        title: 'Xóa chương trình tích điểm!',
        text: 'Bạn có chắc muốn xóa chương trình tích điểm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(result => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    onContext(ctx) {
      // // The date formatted in the locale, or the `label-no-date-selected` string
      // this.formatted = ctx.selectedFormatted
      // // The following will be an empty string until a valid date is entered
      if (ctx.selectedYMD == '') {
        this.birthday = '';
      } else {
        this.birthday = ctx.selectedYMD;
      }
      this.fetchData();
    },
    checkType(value) {
      switch (value) {
        case 1:
          return 'Khách lẻ';
        case 2:
          return 'Khách buôn';
        case 3:
          return 'Đại lý';
        default:
          return '';
      }
    },
    checkGender(value) {
      switch (value) {
        case 0:
          return 'Nữ';
        case 1:
          return 'Nam';
        default:
          return '';
      }
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    debounceInput: decounce(function() {
      this.fetchData();
    }, 2000),
    fetchCompany: async function() {
      this.listCompany = [];
      ApiService.setHeader();
      ApiService.get('/company').then(response => {
        this.listCompany = response.data.data;
      });
    },
    savePointSetting() {
      let data = {
        id: this.pointSettingId,
        pointRate: unMaskPrice(this.pointRate),
        moneyToPoint: unMaskPrice(this.moneyToPoint),
        pointToMoney: this.pointToMoney,
        minPoint: unMaskPrice(this.minPoint),
        maxPoint: unMaskPrice(this.maxPoint),
        autoPoint: unMaskPrice(this.autoPoint),
        allowAddPointProductService: this.allowAddPointProductService,
        allowAddPointRoundToDown: this.allowAddPointRoundToDown,
        smsPoint: this.sendSMS,
        companyId: this.selectedCompany
      };
      ApiService.setHeader();
      ApiService.post('pointSetting/', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess('Lưu cài đặt tích điểm thành công!');
            this.getPointSetting();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    getPointSetting() {
      ApiService.setHeader();
      ApiService.get(`pointSetting`).then(({ data }) => {
        this.pointSettingId = data.data.id;
        this.pointRate = data.data.pointRate;
        this.pointToMoney = data.data.pointToMoney;
        this.moneyToPoint = data.data.moneyToPoint;
        this.maxPoint = data.data.maxPoint;
        this.minPoint = data.data.minPoint;
        this.autoPoint = data.data.autoPoint;
        this.allowAddPointProductService =
          data.data.allowAddPointProductService === 1 ? true : false;
        this.allowAddPointRoundToDown =
          data.data.allowAddPointRoundToDown === 1 ? true : false;
        this.sendSMS = data.data.sms_point === 1 ? true : false;
        this.selectedCompany = data.data.companyId;
      });
    }
  }
};
</script>
